export default class StringUtils {
  static RandomString(length = 16) {
    let str = '';
    while (str.length < length) {
      str += Math.random()
        .toString(36)
        .substring(2, length + 2); // offset by 2 to remove the "0." from the string
    }

    return str.substring(0, length);
  }

  static CamelCaseToSnakeCase(str: string): string {
    let result = '';

    for (let i = 0; i < str.length; i++) {
      const char = str[i];

      // If the character is uppercase, append an underscore and the lowercase version of the character
      if (char >= 'A' && char <= 'Z') {
        // Add an underscore before the character if it's not the first character
        if (i > 0) {
          result += '_';
        }
        result += char.toLowerCase();
      } else {
        result += char;
      }
    }

    return result;
  }

  static SnakeCaseToCamelCase(str: string): string {
    let result = '';
    let capitalizeNext = false;

    for (let i = 0; i < str.length; i++) {
      const char = str[i];

      if (char === '_') {
        capitalizeNext = true;
      } else {
        result += capitalizeNext ? char.toUpperCase() : char;
        capitalizeNext = false;
      }
    }

    return result;
  }
}

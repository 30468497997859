import StringUtils from '@/StringUtils';
import fastDeepEqual from 'fast-deep-equal';

export default class ObjectUtils {
  static IsPlainObject(obj: any): boolean {
    if (obj === null || typeof obj !== 'object') {
      return false;
    }
    return Object.getPrototypeOf(obj) === Object.prototype;
  }

  static TransformKeys(obj: object, fn: (key: string) => string) {
    if (!ObjectUtils.IsPlainObject(obj)) {
      return obj;
    }

    const keys = Object.keys(obj);
    for (const key of keys) {
      const newKey = fn(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }

      if (Array.isArray(obj[newKey])) {
        obj[newKey] = obj[newKey].map((item: any) => ObjectUtils.TransformKeys(item, fn));
      } else {
        obj[newKey] = ObjectUtils.TransformKeys(obj[newKey], fn);
      }
    }

    return obj;
  }

  static TransformKeysCamelCaseToSnakeCase(obj: object) {
    return ObjectUtils.TransformKeys(obj, StringUtils.CamelCaseToSnakeCase);
  }

  static TransformKeysSnakeCaseToCamelCase(obj: object) {
    const transformed = ObjectUtils.TransformKeys(obj, StringUtils.SnakeCaseToCamelCase);
    return transformed;
  }

  static GroupByKey<K extends string, T extends Record<K, any>>(array: T[], key: K = 'id' as K) {
    return array.reduce(
      (acc, item) => {
        acc[item[key]] = item;
        return acc;
      },
      {} as Record<T[K], T>,
    );
  }

  static DeepClone(obj: any) {
    return structuredClone(obj);
  }

  static DeepEqual(a: any, b: any) {
    return fastDeepEqual(a, b);
  }
}
